import Control from '@/js/Controls/Control'
import { tns } from 'tiny-slider/src/tiny-slider'
import { TinySliderInstance, TinySliderSettings } from 'tiny-slider'
import React from 'jsx-dom'
import { deepMerge } from 'collapsable.js'

interface HTMLCarouselElement extends HTMLElement {
	carousel: TinySliderInstance
}

class CarouselControl implements Control {
	public initialize(context: Element | Document): void {
		const carousels = context.querySelectorAll<HTMLCarouselElement>('.js-carousel')

		carousels.forEach((carouselWrapper) => {
			const container = carouselWrapper.querySelector<HTMLElement>('.js-carousel__carousel')
			if (!container) return

			const itemsCount = container.querySelectorAll('.js-carousel__item').length
			if (itemsCount <= 1) return

			const dataOptions = JSON.parse(carouselWrapper.dataset.carousel ?? '{}')
			const controlsContainer = '.js-carousel__controls'
			const navContainer = '.js-carousel__nav'
			const autoplayButton = '.js-carousel__pause'

			const defaultOptions: TinySliderSettings = {
				container,
				controlsContainer,
				navContainer,
				autoplayButton,
				autoplayText: [
					'<span class="iconify-[mdi--play]"></span>',
					'<span class="iconify-[mdi--pause]"></span>'
				],
				loop: true
			}

			const options = deepMerge({}, defaultOptions, dataOptions)

			carouselWrapper.carousel = tns(options)

			if (carouselWrapper.classList.contains('js-carousel--names')) {
				const navContainerElement = carouselWrapper.querySelector<HTMLElement>(navContainer)
				if (!navContainerElement) return

				carouselWrapper.carousel.events.on('indexChanged', () =>
					this.checkVisibleName(
						navContainerElement,
						navContainerElement.parentElement,
						carouselWrapper.carousel.getInfo().index
					)
				)
			}
		})
	}

	private checkVisibleName(nav: HTMLElement, parent: HTMLElement | null, index: number) {
		const wrapWidth = nav.offsetWidth
		const page = nav.children.item(index - 1) as HTMLElement

		if (page && parent) {
			const pageWidth = page.offsetWidth
			const pageLeft = page.offsetLeft

			if (pageLeft + pageWidth < parent.scrollLeft) {
				parent?.scrollTo({
					top: 0,
					left: pageLeft,
					behavior: 'smooth'
				})
			}

			if (pageWidth + pageLeft > wrapWidth + parent.scrollLeft) {
				parent?.scrollTo({
					top: 0,
					left: pageWidth + pageLeft - wrapWidth,
					behavior: 'smooth'
				})
			}
		}
	}
}

export default new CarouselControl()
